<template>
    <div class="putInto">
            <van-cell size="small"
                      title="报备日期"
                      :value="date"
                      @click="show = true" />
            <van-calendar color="#3F7C53" v-model="show"
                          :min-date="minDate"
                          :default-date ='defaultDate'
                          type="range" @confirm="onConfirm" />
            <div class="button-box">
                <van-button plain type="primary" color="#3F7C53" size="small" class="m-l-15"
                            @click="reimburse">报销</van-button>
                <van-button plain type="primary" color="#3F7C53" size="small" class="m-l-15"
                            @click="putInfoItem">入库</van-button>
                <van-button type="primary" color="#3F7C53" size="small"
                            class="m-l-15" @click="search">查 询</van-button>
            </div>
            <van-pull-refresh v-model="isLoadingGoods"
                              @refresh="goodsOnRefresh">
                <van-list
                    v-model="goodsLoading"
                    :finished="goodsFinished"
                    finished-text="没有更多了"
                    @load="goodsOnLoad">
                    <div class="public-card put-storage" v-for="(report,idx) in reportList" :key="idx">
                        <div class="put-title">{{report.spNo}} - {{report.reimburseStatus}}</div>
                        <div class="public-flex m-b-15">
                            <div class="put-time">{{report.name}}</div>
                            <div class="put-time">{{report.applyTime}}</div>
                            <van-checkbox
                                    :disabled="report.reimburseStatus=='报销中'||report.reimburseStatus=='已报销'"
                                    checked-color="#3F7C53"
                                    v-model="report.checked"> </van-checkbox>
                        </div>
                        <div class="public-flex m-b-15">
                            <div class="put-time">报备依据：{{report.reportAccording}}</div>
                        </div>
                        <div class="public-flex public-just-around">
                            <div class="column-display">
                                <div class="put-unit-price">{{report.reportMoney}}</div>
                                <div class="all-title">报备金额</div>
                            </div>
                            <div class="column-display">
                                <div class="put-num">{{report.purchaseMoney}}</div>
                                <div class="all-title">采购金额</div>
                            </div>
                        </div>
                    </div>
            </van-list>
            </van-pull-refresh>

        </div>

</template>

<script>
    import {reportList,putInfoItem} from '@/utils/putInto';
    export default {
        name: "reportList.vue",
        data(){
            return{
                date:'',
                defaultDate:[new Date((new Date()  - 1000 * 60 * 60 * 24 * 60)),new Date()] ,
                show:false,
                minDate: new Date(2022,1,1),
                reportStartDate:'',//报备开始日期 yyyy-MM-dd
                reportEndDate:'',//报备结束日期 yyyy-MM-dd
                pageIndex:1,//页码
                pageSize:10,
                reportList:[],
                goodsLoading:false,
                goodsFinished:false,
                isLoadingGoods:false,
                columns:[]
            }
        },
        created(){
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            let startD = myDate.getDate();
            let myArr = startY +'-'+ (startM < 10 ? '0' + startM :startM) +'-'+(startD< 10 ? '0'+ startD : startD);
            //获取三十天前日期
            let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 60);//最后一个数字30可改，30天的意思
            let lastY = lw.getFullYear();
            let lastM = lw.getMonth() + 1;
            let lastD = lw.getDate();
            let startdate = lastY +'-'+ (lastM < 10 ? '0' + lastM : lastM) +'-'+(lastD < 10 ? '0'+ lastD : lastD);
            this.date = startdate+'至'+ myArr;
            this.reportStartDate = startdate;
            this.reportEndDate = myArr;
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },

            //时间处理
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
            },
            onConfirm(date) {
                const [start, end] = date;
                this.show = false;
                this.date = `${this.formatDate(start)}至${this.formatDate(end)}`;
                this.reportStartDate = this.formatDate(start);
                 this.reportEndDate = this.formatDate(end);
            },
            //报备查询
            search(){
                this.getReportList(true);
            },
            //上拉加载1
            goodsOnLoad(){
                this.getReportList();
            },
            getReportList(flag){
                if(flag){
                    this.pageIndex = 1
                }
                let data = {
                    reportStartDate:this.reportStartDate,//开始日期 yyyy-MM-dd
                    reportEndDate:this.reportEndDate,//结束日期 yyyy-MM-dd
                    pageIndex:this.pageIndex,//页码
                    pageSize:this.pageSize
                }
                reportList(data).then(res=>{
                    this.goodsLoading = false;
                    if(res.code===200 && res.data.businessCode===200){
                        this.goodsFinished = !res.data.data.hasNextPage;
                        if (this.pageIndex === 1) {
                            this.reportList = res.data.data.reportDetail;
                        } else {
                            this.reportList = this.reportList.concat(res.data.data.reportDetail);
                        }
                        if (!this.goodsFinished) {
                            this.pageIndex++;
                        }
                    }

                })
            },
            goodsOnRefresh() {
                this.isLoadingGoods = false
                this.reportList = []
                this.pageIndex = 1
                this.reportList()
            },
            reimburse(){
                let arr = [];
                let oldArr = this.reportList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked){
                        if(item.purchaseMoney=='0'||item.purchaseMoney=='0.0'||item.purchaseMoney=='0.00'){
                            this.$message.warning('采购金额不能为0，请先做入库操作!')
                            return;
                        }
                        if(item.enterpriseBuy=='1'){
                            this.$message.warning('总部集采，不允许报销!')
                            return;
                        }
                        let obj = {}
                        obj.id = item.spNo;
                        arr.push(obj);
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择需要报销的记录!')
                    return;
                }
                console.log('arr',arr);
                this.$router.push({
                    name: 'reimburse',
                    params: { data: arr }
                })
            },
            putInfoItem(){
                let arr = [];
                let oldArr = this.reportList;
                for(let i = 0; i < oldArr.length ; i++) {
                    let item = oldArr[i];
                    if(item.checked){
                        let obj = {}
                        obj.id = item.spNo;
                        arr.push(obj);
                    }
                }
                if(arr.length <= 0){
                    this.$message.warning('请选择需入库的报备记录!')
                    return;
                }
                if(arr.length > 1){
                    this.$message.warning('请仅选择一条需入库的报备记录!')
                    return;
                }
                console.log('arr',arr);
                putInfoItem({transferGoodsList:arr}).then(res=>{
                    console.log('数据',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.$message.success('报备物品已成功入库！')
                        this.search();
                    }
                })

            },


        }
    }
</script>

<style lang="less" >
    @import "../../style/putInto.less";
    .van-tab{
        font-size: 0.35rem;
    }
    .van-cell{
       font-size: 12px;
    }
</style>
